export const CategoryType = {
  Navigation: "Navigation",
  Axios: "Axios",
  postUserAnswer: "Submited form answers",
  User: "User",
  UnClassifedError: "UnClassifedError",
  FollowUpSubmit: "Follow_Up_submit",
} as const;

export const LabelType = {
  submittedUserAnswer: "submitted user answer",
  submittedUserContact: "submmiteed user contact",
};

export const ActionType = {
  clickedOrganisationWebsite: "clicked organisation website",
  clickedOrganisationMapLink: "clicked organisation map link",
  clickedFollowUp: "clicked follow up",
  clickedStartTest: "clicked start test",
};

export const NavigationActionType = {} as const;

export const ModalViewType = {} as const;

export const ExceptionType = {
  Axios: "AxiosError",
  UnClassifed: "UnClassifedError",
} as const;
