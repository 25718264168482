const toastIds = {
  NETWORK_ERROR: "NETWORK_ERROR",
  UNAUTHORIZED: "UNAUTHORIZED",
  UNAUTHORIZED2: "UNAUTHORIZED2",
  FORBIDDEN: "FORBIDDEN",
  FORBIDDEN2: "FORBIDDEN2",
  BADREQUEST: "BADREQUEST",
  CONFLICTS: "CONFLICTS",
  CONFLICTS2: "CONFLICTS2",
  INTERNAL_SERVER_ERROR: "INTERNAL_SERVER_ERROR",
  UNEXPECTED_ERROR: "UNEXPECTED_ERROR",
} as const;

export default toastIds;
