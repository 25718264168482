import { ReactElement, useState } from "react";

interface MulitistepFormProps {
  data: {
    form1: number[];
    form2: string[];
    form3: string[];
    form4: string[];
    form5: number[];
    form6: string[];
  };
}

export function useMultistepForm(
  steps: ReactElement[],
  props: MulitistepFormProps,
) {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [page, setPage] = useState<0 | 1 | 2>(0);

  function next() {
    setCurrentStepIndex((i) => {
      if (i >= steps.length - 1) return i;
      return i + 1;
    });
  }

  function back() {
    setCurrentStepIndex((i) => {
      if (i <= 0) return i;
      return i - 1;
    });
  }

  function finish() {
    setPage(1);
  }

  function goTo(index: number) {
    setCurrentStepIndex(index);
  }

  return {
    currentStepIndex,
    step: steps[currentStepIndex],
    steps,
    isFirstStep: currentStepIndex === 0,
    isLastStep: currentStepIndex === steps.length - 1,
    goTo,
    next,
    back,
    finish,
  };
}
