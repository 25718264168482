import { Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/system";

type BaseButtonProps = ButtonProps & {};

const SelectBaseButton = styled(Button)<BaseButtonProps>(({ theme }) => ({
  backgroundColor: "#FFFFF",
  color: theme.palette.primary.main,
  fontWeight: "medium",
  height: "auto",
  minHeight: "70px",
  width: "400px",
  maxWidth: "700px",
  fontSize: "18px",
  borderRadius: "1000px",
  border: `1px solid ${theme.palette.primary.main}`,
  "&:hover": {
    // backgroundColor: theme.palette.primary.dark,
  },
  "&:disabled": {
    border: "none",
    backgroundColor: "#EAE8E5",
    color: "#C3BAB5",
  },
  "&:active": {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default SelectBaseButton;
