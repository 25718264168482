import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import ReactGA from "react-ga4";
import { useEffect } from "react";
import MainPage from "../components/MainPage";
import NotFoundPage from "../components/NotFoundPage";
import { ROUTE_PATHS } from "../utils/constants/routes/webPath";
import { useQuery } from "@tanstack/react-query";
import REACT_QUERY_KEYS from "../utils/constants/reactQueryKeys";
import { getTheme } from "../services/theme";
import {
  Box,
  createTheme,
  Grid,
  LinearProgress,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material";

const AppRoutes = (props: any) => {
  // useEffect(() => {
  //   ReactGA.send("pageview");
  // }, [location.pathname]);

  const location = useLocation();
  const { data: themeData, isLoading } = useQuery(
    [REACT_QUERY_KEYS.DB_PUBLIC_THEME_DATA],
    () => getTheme(location.pathname),
    {
      enabled: true,
      retry: true,
    },
  );

  let theme = createTheme({
    palette: {
      // https://mui.com/material-ui/customization/palette/
      text: {
        primary: themeData?.data
          ? themeData?.data.text_primary_color
          : "#999999",
        secondary: themeData?.data
          ? themeData?.data.text_secondary_color
          : "#999999",
      },
      primary: {
        main: themeData?.data ? themeData?.data.primary_main_color : "#999999", //blue
      },
      secondary: {
        main: themeData?.data
          ? themeData?.data.secondary_main_color
          : "#999999", //lightgreen (main page bg color)
      },
      info: {
        main: themeData?.data ? themeData?.data.info_main_color : "#999999", //orange
        light: themeData?.data ? themeData?.data.info_light_color : "#999999", //pink
      },
    },
  });
  theme = responsiveFontSizes(theme);

  if (isLoading)
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        {[ROUTE_PATHS.LUNGCANCER].map((e, idx) => (
          <Route
            caseSensitive={true}
            path={e}
            key={idx}
            element={<MainPage />}
          ></Route>
        ))}

        <Route path={ROUTE_PATHS.INDEX} element={<NotFoundPage />}></Route>
      </Routes>
    </ThemeProvider>
  );
};

export default AppRoutes;
