import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useMultistepForm } from "../../utils/helperFunctions/useMultiStepForm";
import { Grid, useTheme, Typography } from "@mui/material";
import BaseButton from "../shareComponents/button/BaseButton";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import { UserAnswerProps } from "../../utils/types/services/userAnswer";
import { useMutation, useQuery } from "@tanstack/react-query";
import { addUserAnswer } from "../../services/userAnswer";
import REACT_QUERY_KEYS from "../../utils/constants/reactQueryKeys";
import { getStepFormOption } from "../../services/stepFormOptions";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import PatientorCaregiverForm from "./PatientorCaregiverForm";
import PersonalInfoForm from "./PersonalInfoForm";
import DiagnosisForm from "./DiagnosisForm";
import BiomarkerorGenticForm from "./BiomarkerorGenticForm";
import SpecialistForm from "./SpecialistForm";
import TreatementQuestionsForm from "./TreatementQuestionsForm";
import SourceofKnowingForm from "./SourceofKnowingForm";
import SupportTypeForm from "./SupportTypeForm";
import TroubleForm from "./TroubleForm";
import KindofSupportForm from "./KindofSupportForm";
import { UserResult } from "../../utils/types/services/userResult";
import { getTheme } from "../../services/theme";

interface StartTestProps {
  page: number;
  setPage: (page: number) => void;
  data: UserAnswerProps;
  setData: (value: React.SetStateAction<UserAnswerProps>) => void;
  userResult: UserResult | undefined;
  setUserResult: (value: UserResult | undefined) => void;
}

const StartTest = (props: StartTestProps) => {
  const location = useLocation();
  const { data: themeData } = useQuery(
    [REACT_QUERY_KEYS.DB_PUBLIC_THEME_DATA],
    () => getTheme(location.pathname),
    {
      enabled: true,
      retry: true,
    }
  );
  const cancer_logo = themeData?.data?.icon;

  const {
    data: resultData,
    isLoading,
    isSuccess,
    mutateAsync,
  } = useMutation(() => addUserAnswer(props.data, currentCancerType), {
    onSuccess: (data) => {},
    onError: () => {},
    onSettled: (data) => {
      props.setUserResult(data?.result);
    },
  });

  const currentCancerType = location.pathname;
  const { data: stepFormOptions } = useQuery(
    [REACT_QUERY_KEYS.DB_PUBLIC_STEP_FORM_OPTION],
    () => getStepFormOption(currentCancerType),
    {
      enabled: true,
      retry: true,
    }
  );


 
  
console.log(stepFormOptions,"stepFormOptions");

console.log(stepFormOptions,"data");


  const theme = useTheme();

  const updateForm1 = (data: number[]) => {
    props.setData((val) => ({
      ...val,
      form1: data,
    }));
  };
  const updateForm2 = (data: string[]) => {
    props.setData((val) => ({
      ...val,
      form2: data,
    }));
  };

  const updateForm3 = (data: string[]) => {
    props.setData((val) => ({
      ...val,
      form3: data,
    }));
  };
  const updateForm4 = (data: string[]) => {
    props.setData((val) => ({
      ...val,
      form4: data,
    }));
  };
        const updateForm5 = (data: number[]) => {
        props.setData((val) => ({
          ...val,
          form5: data,

        }));
      };
      const updateForm6 = (data: string[]) => {
        props.setData((val) => ({
          ...val,
         form6: data,
          

        }));
      };


      const updateForm7 = (data: number[]) => {
        props.setData((val) => ({
          ...val,
          form7: data,

        }));
      };
      const updateForm8 = (data: number[]) => {
        props.setData((val) => ({
          ...val,
          form8: data,
        }));
      };
      const updateForm9 = (data: number[]) => {
        props.setData((val) => ({
          ...val,
          form9: data,
        }));
      };
      const updateForm10 = (data: string | null) => {
        if (data !== null) {
          props.setData((val) => ({
            ...val,
            form10: [data],
          }));
        } else {
          props.setData((val) => ({
            ...val,
            form10: [],
          }));
        }
      };
    
  const { steps, currentStepIndex, step, isFirstStep, isLastStep, back, next } =
  
    useMultistepForm(
      [ 
        <PatientorCaregiverForm
          updateAnswer1={updateForm1}
          data={props.data.form1}
          optionSet={stepFormOptions?.data}
        />,
        <PersonalInfoForm
        updateAnswer2={updateForm2}
        data={props.data.form2}
        dropdownset={stepFormOptions?.dropdowns}
        />,
        <DiagnosisForm
        updateAnswer3={updateForm3}
        data={props.data.form3}
        optionSet={stepFormOptions?.data}
        dropdownset={stepFormOptions?.dropdowns}
        />,
        <BiomarkerorGenticForm
        updateAnswer4={updateForm4}
        data={props.data.form4}
        optionSet={stepFormOptions?.data}
        dropdownset={stepFormOptions?.dropdowns}
        />,
        <SpecialistForm
        updateAnswer5={updateForm5}
        data={props.data.form5} 
        optionSet={stepFormOptions?.data}
        />,
        <TreatementQuestionsForm
        updateAnswer6={updateForm6}
        data={props.data.form6} 
        dropdownset={stepFormOptions?.dropdowns}     
        />,
      <TroubleForm
          updateAnswer7={updateForm7}
          data={props.data.form7}
          optionSet={stepFormOptions?.data}
        />,

        <SupportTypeForm
          updateAnswer8={updateForm8}
          data={props.data.form8}
          optionSet={stepFormOptions?.data}
        />,


        <KindofSupportForm
          updateAnswer9={updateForm9}
          data={props.data.form9}
          optionSet={stepFormOptions?.data}
        />,


        <SourceofKnowingForm
          updateAnswer10={updateForm10}
          data={props.data.form10.length > 0 ? props.data.form10[0] : null}
          dropdownset={stepFormOptions?.dropdowns}
        />,
      
      ],
      { data: props.data }
    );

  const finishedQuestion = useMemo(() => {


    
      return Object.values(props.data)[currentStepIndex].length > 0; // Return based on another condition
    
  }, [currentStepIndex, props.data]);

  const page= function(){

    console.log(currentStepIndex);
    if(currentStepIndex==5){
    if(!props.data.form6.hasOwnProperty(0) || !props.data.form6.hasOwnProperty(1) || !props.data.form6.hasOwnProperty(2) || !props.data.form6.hasOwnProperty(3) ||  props.data.form6.some(str => str.split("  ").includes("input1") || str.split("  ").includes("input2") || str.split("  ").includes("input3") || str.split("  ").includes("input4")) )
      return true;}

    return false;
  };

  console.log(currentStepIndex);


  const formDataArray = [
    props.data.form1,
    props.data.form2,
    props.data.form3,
    props.data.form4,
    props.data.form5,
    props.data.form6,
    props.data.form7,
    props.data.form8,
    props.data.form9,
    props.data.form10,
    // Add more form arrays as needed
  ];

  


  // if (currentStepIndex === 6 || currentStepIndex === 7 || currentStepIndex === 8) {
  //   for (let i = 0; i < 2; i++) {
  //     const desiredValue = formDataArray[currentStepIndex][i];
  //  // Change this to the value you're interested in
  

  // // Ensure stepFormOptions is defined before using it
  // if (stepFormOptions) {
  //   // Find the object with the desired value
  //   const desiredObject = stepFormOptions.data?.find(option => option.value == desiredValue &&  option.formStep === (currentStepIndex+1));
  
  //   // Check if the object with the desired value exists
  //   if (desiredObject) {
  //     const description = desiredObject.description;
  //     const display=props.data.form11.push(description);
  //     console.log(display); // This will log the description corresponding to the desired value
  //   } else {
  //     console.log("No description found for the given value.");
  //   }
  // } else {
  //   console.log("Step form options are not available.");
  // }
  

  //   }
  // }
  // else{
 
  // const desiredValue = formDataArray[currentStepIndex][0];
  //  // Change this to the value you're interested in
  

  // // Ensure stepFormOptions is defined before using it
  // if (stepFormOptions) {
  //   // Find the object with the desired value
  //   const desiredObject = stepFormOptions.data?.find(option => option.value == desiredValue &&  option.formStep === (currentStepIndex+1));
  
  //   // Check if the object with the desired value exists
  //   if (desiredObject) {
  //     const description = desiredObject.description;
  //     const display=props.data.form11.push(description);
  //     console.log(display); // This will log the description corresponding to the desired value
  //      // This will log the description corresponding to the desired value
  //   } else {
  //     console.log("No description found for the given value.");
  //   }
  // } else {
  //   console.log("Step form options are not available.");
  // }}
  



  return (
    <Grid
      sx={{
        margin: {
          xs: "0 5% 0 5%",
          md: "0 10% 0 10%",
          lg: "0 20% 0 20%",
        },
      }}
    >
      <Grid sx={{ width: "200" }}>
        <Box
          sx={{
            width: "100%",
            padding: "0px 0 20px 0",
          }}
        >
          <Grid
            container
            item
            justifyContent="center"
            direction="row"
            sx={{
              height: "5rem",
              paddingBottom: 10,
              paddingTop: { xs: 2.5, md: 4, lg: 5 },
            }}
          >
            <img
              src={cancer_logo}
              style={{
                height: "4.5rem",
              }}
            />
          </Grid>
          <Grid container item justifyContent="center" direction="row">
          <Stepper
          activeStep={currentStepIndex / 2} 
          alternativeLabel
          sx={{ width: 550 }}
          >
          {steps.map((label, index) => (
          index % 2 === 0 && (
          <Step key={index}>
         <StepLabel></StepLabel>
         </Step>
         )
         ))}
         </Stepper>
          </Grid>
        </Box>
        <Grid container item display="flex" justifyContent="center">
          {step}
        </Grid>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingTop: 5,
            paddingBottom: 5,
          }}
        >
          {!isFirstStep && (
            <BaseButton
              onClick={back}
              type="button"
              sx={{
                backgroundColor: "transparent",
                color: `${theme.palette.primary.main}`,
                "&:hover": {
                  backgroundColor: "#E8EAED",
                },
                marginRight: 1,
              }}
              disabled={isLoading}
            >
              <KeyboardArrowLeftRoundedIcon />
              <Typography>返回</Typography>
            </BaseButton>
          )}
          {isLastStep ? (
            <BaseButton
              sx={{
                marginLeft: 1,
              }}
              onClick={async () => {
                const result = await mutateAsync();
                if (result.result) {
                  props.setData({
                    form1: [],
                    form2: [],
                    form3: [],
                    form4: [],
                    form5: [],
                    form6: [],
                    form7: [],
                    form8: [],
                    form9: [],
                    form10: []
                    

                  });

                
                
                  console.log(props.setData);
                  props.setPage(2);
                }
              }}
              type="button"
              disabled={ !finishedQuestion || isLoading}
            >
              <Typography>完成</Typography>
            </BaseButton>
          ) : (
            <BaseButton
              sx={{
                marginLeft: 1,
              }}
              onClick={next}
              disabled={  page() || !finishedQuestion || isLoading}
              type="button"
            >
              <Typography>下一題</Typography>
              <KeyboardArrowRightRoundedIcon />
            </BaseButton>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StartTest;
