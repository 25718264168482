import { Typography, Grid } from "@mui/material";

interface NotFoundPageProps {}

const NotFoundPage = (props: NotFoundPageProps) => {
  const {} = props;

  return (
    <Grid
      container
      item
      xs={12}
      sx={{
        padding: "1%",
        backgroundColor: "transparent",
      }}
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid item xs={12}>
        <Typography
          variant="h5"
          textAlign={"left"}
          sx={{
            fontWeight: "bold",
          }}
        >
          404 找不到網頁
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NotFoundPage;
