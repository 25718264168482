import React, { useState } from "react";
import { useTheme, SxProps } from "@mui/material";

const useButtonCSSHook = () => {
  const theme = useTheme();

  const selectedBtn: SxProps = {
    backgroundColor: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.secondary.main}`,
    color: "theme.palette.primary.main",
    paddingLeft: "5%",
    paddingRight: "5%",
    width: "100%",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  };
  const defaultBtn: SxProps = {
    backgroundColor: "#FFFFFF",
    border: `1px solid ${theme.palette.primary.main}`,
    color: "theme.palette.primary.main",
    paddingLeft: "5%",
    paddingRight: "5%",
    width: "100%",
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
  };

  return {
    selectedBtn,
    defaultBtn,
  };
};

export default useButtonCSSHook;
