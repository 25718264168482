import * as React from "react";
import Drawer from "@mui/material/Drawer";
import { Grid, Typography } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

type Anchor = "bottom";

interface PICSProps {
  title: string;
  color: string; // Add color prop
}

const PICS: React.FC<PICSProps> = ({ title, color }) => {
  const [state, setState] = React.useState({
    bottom: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  return (
    <>
      {(["bottom"] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <Typography
            component={"span"}
            onClick={toggleDrawer(anchor, true)}
            sx={{
              color: color, // Use the passed color prop here
              display: "inline-block",
              textDecoration: "underline",
              "&:hover": {
                textDecoration: "underline",
                cursor: "pointer",
              },
            }}
          >
            {title}
          </Typography>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            <Grid
              sx={{
                padding: {
                  xs: "3% 5% 0 5%",
                  md: "3% 10% 0 10%",
                  lg: "3% 20% 0 20%",
                },
              }}
            >
              <Grid container justifyContent="space-between" >
                <Typography
                  variant="h5"
                  sx={{  fontWeight: 900, paddingBottom: 2, textAlign: 'center' }}
                >
                  PERSONAL INFORMATION COLLECTION STATEMENT (&quot;PICS&quot;)
                </Typography>
                <Typography
                  variant="h5"
                  sx={{  fontWeight: 900, paddingBottom: 2 }}
                >
                  個人資料收集聲明（「本聲明」）
                </Typography>
                <CloseRoundedIcon
                  sx={{
                    fontSize: 40,
                    color: "#706B69",
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                  onClick={toggleDrawer(anchor, false)}
                />
              </Grid>
              <Typography variant="subtitle1" sx={{ paddingBottom: 2 }}>
                This PICS outlines how Roche Hong Kong Limited (&quot;we&quot;, &quot;us&quot; or &quot;our&quot;) will collect and process your data in accordance
with our <a href="https://www.roche.com.hk/privacy-statement">Privacy Statement</a>.
              </Typography>



              <Typography variant="subtitle1" sx={{ paddingBottom: 6 }}>
                本聲明概述了羅氏大藥廠 (以下稱「我們」）將如何根據我們的<a href="https://www.roche.com.hk/privacy-statement">隱私聲明</a>收集和處理您的信息。
              </Typography>


            





              <Typography
                variant="h6"
                sx={{ fontWeight: 700, paddingBottom: 2 }}
              >
                Data collection
              </Typography>
              <Typography variant="subtitle1" sx={{ paddingBottom: 6 }}>
              We will collect your personal and medical data (“Your Data”) whether past, present, and/or future, including but not
limited to your name, email, gender, age range, economic standing, medical history, drug usage, treatment received
and other physical or mental health information. Providing Your Data is mandatory for participating in the Cancer 101
Program.
              </Typography>



              <Typography
                variant="h6"
                sx={{ fontWeight: 700, paddingBottom: 2  }}
              >
                收集信息
              </Typography>
              <Typography variant="subtitle1" sx={{ paddingBottom: 6 }}>
              我們將收集您過去、現在和/或將來的個人和醫療信息（「您的信息」），包括但不限於您的姓名、電子郵件、性別、年
齡範圍、經濟狀況、病史、藥物使用、接受的治療以及其他身體或心理健康信息。為參與《癌症支援平台》，提供您的
信息是必需要的。
              </Typography>



        



              <Typography
                variant="h6"
                sx={{ fontWeight: 700, paddingBottom: 2 }}
              >
                Purposes of collection
              </Typography>
              <Typography variant="subtitle1" sx={{ paddingBottom: 6 }}>
              We may use Your Data for the following purposes from time to time: -

              <ul>
  <li>to administer the Cancer 101 Program;</li>
  <li>to conduct analytical research and assessments aimed at enhancing our products, services, and
collaboration with partners, such as supporting groups and medical service providers;</li>
  <li>to comply with our legal obligations; and/or</li>
  <li>other purposes described in our <a href="https://www.roche.com.hk/privacy-statement">Privacy Statement</a>.</li>
</ul>
              </Typography>


              <Typography
                variant="h6"
                sx={{ fontWeight: 700, paddingBottom: 2 }}
              >
                收集目的
              </Typography>
              <Typography variant="subtitle1" sx={{ paddingBottom: 6 }}>
              我們可能會不時將您的信息用於以下目的：

              <ul>
  <li>管理《癌症支援平台》；</li>
  <li>進行分析研究和評估，旨在改進我們的產品、服務以及與合作夥伴（如支援團體和醫療服務提供者）的合作；</li>
  <li>遵守我們的法律義務；及/或</li>
  <li>我們隱私聲明中闡述的其他目的。</li>
</ul>
              </Typography>


              <Typography
                variant="h6"
                sx={{ fontWeight: 700, paddingBottom: 2 }}
              >
                Data Transfer
              </Typography>
              <Typography variant="subtitle1" sx={{ paddingBottom: 6 }}>
              To facilitate the purposes set out above, we may disclose or transfer Your Data to the following parties (“Roche’s
Partners”), whether within or outside Hong Kong:

              <ul>
  <li>our affiliates;</li>
  <li>our agents, service providers, and business partners, including information technology and medical service
providers, as well as support groups;</li>
  <li>persons under a duty of confidentiality to us, including professionals or research institutions;</li>
  <li>persons to whom we are required to disclose information under any applicable law; and/or</li>
  <li>our assignees or successors.</li>
</ul>
              </Typography>



              <Typography
                variant="h6"
                sx={{ fontWeight: 700, paddingBottom: 2 }}
              >
                信息轉移
              </Typography>
              <Typography variant="subtitle1" sx={{ paddingBottom: 6 }}>
              為了實現上述目的，我們可能會在香港境內或境外向以下各方（「羅氏的合作夥伴」）披露或轉移您的信息：

              <ul>
  <li>我們的關聯公司；</li>
  <li>我們的代理商、服務提供商和業務合作夥伴，包括信息技術和醫療服務提供商以及支援團體；</li>
  <li>對我們負有保密義務的人員，包括專業人士或研究機構；</li>
  <li>根據任何適用法律我們需要向其披露信息的人員；和/或</li>
  <li>我們的受讓人或繼承人。</li>
</ul>
              </Typography>



              <Typography
                variant="h6"
                sx={{ fontWeight: 700, paddingBottom: 2 }}
              >
                Direct marketing
              </Typography>
              <Typography variant="subtitle1" sx={{ paddingBottom: 6 }}>
              Only with your consent, shall we use Your Data for direct marketing and/or provide Your Data to Roche’s Partners for
direct marketing by email or other agreed channels, such as providing relevant cancer information, community
resources, patient campaigns, and other related content. You may withdraw consent at any time by contacting us.

              
              </Typography>



              <Typography
                variant="h6"
                sx={{ fontWeight: 700, paddingBottom: 2 }}
              >
                直接促銷
              </Typography>
              <Typography variant="subtitle1" sx={{ paddingBottom: 6 }}>
              只有在獲得您的同意的情況下，我們才會通過電子郵件或其他約定的通訊渠道使用您的信息進行直接營銷和/或將您的信
息提供給羅氏的合作夥伴進行直接促銷，例如提供相關的癌症信息、社區資源、患者活動以及其他相關內容。您可以隨
時聯繫我們撤回同意。

              
              </Typography>



              <Typography
                variant="h6"
                sx={{ fontWeight: 700, paddingBottom: 2 }}
              >
                Security and storage; Access and correction
              </Typography>
              <Typography variant="subtitle1" sx={{ paddingBottom: 6 }}>
              We will keep Your Data in a secure manner only for as long as necessary to fulfil the above purposes. You may at any
time request access to and correct Your Data in our records.

              
              </Typography>



              <Typography
                variant="h6"
                sx={{ fontWeight: 700, paddingBottom: 2 }}
              >
                安全及保存；查閱和更正
              </Typography>
              <Typography variant="subtitle1" sx={{ paddingBottom: 6 }}>
              我們將以安全的方式保存您的信息，保存時間僅限於實現上述收集目的所需的時間。您可以隨時要求查閱和更正您的信
息。

              
              </Typography>

              <Typography variant="subtitle1" sx={{ paddingBottom: 2 }}>
              For any questions or concerns regarding this PICS, please contact us using the contact information provided in our
              <a href="https://www.roche.com.hk/privacy-statement">Privacy Statement</a>.

              
              </Typography>


              <Typography variant="subtitle1" sx={{ paddingBottom: 6 }}>
              如果您對本聲明有任何疑問或疑慮，請使用我們<a href="https://www.roche.com.hk/privacy-statement">隱私聲明</a>中提供的聯繫方式與我們聯繫。

              
              </Typography>



              <Typography variant="subtitle1" sx={{ paddingBottom: 2 }}>
              If there is any discrepancy between the Chinese and English versions, the English version shall prevail.

              
              </Typography>


              <Typography variant="subtitle1" sx={{ paddingBottom: 6 }}>
              如中英文版本有差異，請以英文版本為準。

              
              </Typography>




              <Typography variant="subtitle1" sx={{ paddingBottom: 2 }}>
              By participating in the Cancer 101 Program, I acknowledge that I have voluntarily disclosed my personal and medical
data to you and Roche&#39;s Partners for purposes set forth in this PICS and agree that such data will be processed as
described in this PICS and your Privacy Statement.
              
              </Typography>


              <Typography variant="subtitle1" sx={{ paddingBottom: 6 }}>
              通過參與《癌症支援平台》，我確認自願向您和羅氏的合作夥伴披露我的個人和醫療信息，用於本聲明中規定的目的，
並同意您按照本聲明和羅氏的隱私聲明中所述方式處理這些信息。
              
              </Typography>







            </Grid>
          </Drawer>
        </React.Fragment>
      ))}
    </>
  );
};

export default PICS;
