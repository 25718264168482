import React, { useState } from "react";
import { Button, Card, CardContent, Checkbox, Grid, TextField, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getTheme } from "../../services/theme";
import REACT_QUERY_KEYS from "../../utils/constants/reactQueryKeys";
import { sendSurveyResult } from "../../services/sendResultAsEmail";
import { toast } from "react-toastify";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DisclaimerDrawer from "../shareComponents/drawer/DisclaimerDrawer";

interface SupportRequestSectionProps {
  disableFields: boolean;
  userId: number ;
}



const SupportRequestSection = ({ disableFields, userId }: SupportRequestSectionProps) => {
 const [name, setName] = useState("");
 const [email, setEmail] = useState("");
 const [consentChecked, setConsentChecked] = useState(false);
 const title = "個人資料收集聲明";
 


 const [showAdditionalInputs, setShowAdditionalInputs] = useState(true);


 const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
   setName(event.target.value);
 };


 const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
   setEmail(event.target.value);
 };


 const handleConsentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
   setConsentChecked(event.target.checked);
 };


 const handleSubmit = async (userId: number) => {
   
     try {
       const response = await sendSurveyResult(userId,email,name, consentChecked );
       if(response){
         toast.success("Survey Results Sent Successfully");
         setShowAdditionalInputs(false);
       }
       console.log("Support request submitted successfully", response);
     } catch (error) {
       console.error("Error submitting support request", error);
     }
   
 };


 const handleSubmit2 =  () => {
  setEmail("");
  setName("");
  setConsentChecked(false);

   
 setShowAdditionalInputs(true);

};


 const location = useLocation();
 const { data: themeData, isLoading } = useQuery(
   [REACT_QUERY_KEYS.DB_PUBLIC_THEME_DATA],
   () => getTheme(location.pathname),
   {
     enabled: true,
     retry: true,
   }
 );
 const cancer_logo = themeData?.data?.icon;


 return (
  <Grid>
  {showAdditionalInputs && (
    <Card variant="outlined" sx={{ width: "89%", margin: "auto" }}>
   
     <CardContent>
       <Grid container spacing={2} alignItems="center" justifyContent="center">
         
       <Grid container item xs={12} alignItems="center" justifyContent="center">
  <Grid item>
    <img
      src={cancer_logo}
      style={{ height: 60, marginTop: 15, marginBottom: 5, marginRight: 20 }}
      alt="Cancer Logo"
    />
  </Grid>
  <Grid item>
    <Typography variant="body1" gutterBottom fontWeight="600">
      如需索取配對支援結果之副本,請填寫並提<br />
    
      
    </Typography>
    <Typography variant="body1" gutterBottom fontWeight="600" textAlign={'center'}>
    交以下資料。
    </Typography>
  </Grid>
</Grid>



         <Grid item xs={10} sm={8}>
           <Typography variant="body1" align="left" fontWeight="600" color={"black"}>
             個人資料
           </Typography>
         </Grid>
         <Grid item xs={10} sm={8}>
           <TextField
             label="姓名"
             variant="outlined"
             fullWidth
             size="small"
             value={name}
             onChange={handleNameChange}
             disabled={!disableFields}
           />
         </Grid>
         <Grid item xs={10} sm={8}>
           <TextField
             label="電郵"
             variant="outlined"
             fullWidth
             size="small"
             value={email}
             onChange={handleEmailChange}
             disabled={!disableFields}
           />
         </Grid>
         <Grid item xs={10} sm={8}>
           <Grid container alignItems="center" spacing={1}>
             <Grid item>
               <Checkbox
                 checked={consentChecked}
                 onChange={handleConsentChange}
                 disabled={!disableFields}
                 color="primary"
               />
             </Grid>
             <Grid item>
               <Typography variant="body2" fontWeight="600" color={"black"}>
                 若羅氏大藥廠將來有其他肺癌資訊、社區資源、或舉辦患者活動時，<br/>
                 你希望收到相關的資訊嗎?
               </Typography>
             </Grid>
           </Grid>
         </Grid>
         <Grid item xs={10} sm={8} textAlign={'center'}>
           <Button
             variant="contained"
             color="primary"
             onClick={() => handleSubmit(userId)}
             disabled={!name || !email }

             
           >
             提交 {'>'}
           </Button>
         </Grid>
       </Grid>
     </CardContent>
     </Card>)}








     {!showAdditionalInputs && (
    <Card variant="outlined" sx={{ width: "89%", margin: "auto" }}>
   
     <CardContent>
       <Grid container spacing={2} alignItems="center" justifyContent="center">
         
       <Grid container item xs={12} alignItems="center" justifyContent="center">
  <Grid item>
    <img
      src={cancer_logo}
      style={{ height: 60, marginTop: 15, marginBottom: 5, marginRight: 20 }}
      alt="Cancer Logo"
    />
  </Grid>
  <Grid item>
    <Typography variant="body1" gutterBottom fontWeight="600">
    閣下的配對支援結果已透過電郵發送,再次多謝您使<br />
    
      
    </Typography>
    <Typography variant="body1" gutterBottom fontWeight="600" marginLeft={"10px"}>
    用癌症101的服務。請記住抗癌路上,你絕不孤單!
    </Typography>

    
           <Typography variant="body2" textAlign={"center"} fontWeight="600" color={"black"}>
           溫馨提示:如收不到電郵,請檢視電子郵箱內的
           </Typography>
           <Typography variant="body2" textAlign={"center"} fontWeight="600" color={"black"}>
           「垃圾箱」或「雜件箱」;如仍未能找到電郵,請重做一次。
           </Typography>
         

    
    
  </Grid>

 
</Grid>



        
        
        
        
             
             
          
         
         
       </Grid>
     </CardContent>
     </Card>)}
     </Grid>
  
 );
};


export default SupportRequestSection;



