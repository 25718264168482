export const ROUTE_MAIN_PATHS = {
  dashboard: "/api/dashboard/",
  organisation: "/api/organisation",
  testFrom: "/api/testForm",
  mainContent: "/api/mainContent",
} as const;

export const TEST_FORM_MINIOR_PATHS = {
  testFormPostUserAnswer: "/userAnswer",
  testFormPostUserContact: "/userContact",
  testsendSurveyResults: "/sendSurveyResults",
};
export const MAIN_CONTENT_MINIOR_PATHS = {
  mainContentGetOptions: "/stepFormOptions",
  mainContentGetTheme: "/theme",
};


const generateFullPaths = <T extends { [key in string]: string }>(
  minorPaths: T,
  mainPath: string,
): { [key in keyof T]: T[key] } =>
  Object.entries(minorPaths).reduce(
    (fullPaths, [key, path]) => ({
      ...fullPaths,
      [key]: mainPath + path,
    }),
    {} as { [key in keyof typeof minorPaths]: typeof minorPaths[key] },
  );

const TEST_FORM_FULL_PATHS = generateFullPaths(
  TEST_FORM_MINIOR_PATHS,
  ROUTE_MAIN_PATHS.testFrom,
);

const MAIN_CONTENT_FULL_PATHS = generateFullPaths(
  MAIN_CONTENT_MINIOR_PATHS,
  ROUTE_MAIN_PATHS.mainContent,
);

export const API_ROUTE_FULL_PATHS = {
  ...TEST_FORM_FULL_PATHS,
  ...MAIN_CONTENT_FULL_PATHS,
} as const;
