import * as React from "react";
import { useState, useEffect  } from "react";
import { Grid, Typography, MenuItem, Select, Button, SelectChangeEvent } from "@mui/material";
import { DropdownSet } from "../../utils/types/services/dropdownSet";

interface PersonalInfoProps {
  data: string[];
  updateAnswer2: (data: string[]) => void;
  
  dropdownset?: DropdownSet[];
}

const PersonalInfoForm = (props: PersonalInfoProps) => {
  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");
  const [input4, setInput4] = useState("");

  // Use useEffect to set input1 to props.data[0] when the component mounts
  useEffect(() => {
  
    setInput1(props.data[props.data.findIndex(str => str.includes("91"))] || ""); // Set input1 to props.data[0] or an empty string if props.data[0] is undefined
    setInput2(props.data[props.data.findIndex(str => str.includes("92"))] || "");
    setInput3(props.data[props.data.findIndex(str => str.includes("93"))] || "");
    setInput4(props.data[props.data.findIndex(str => str.includes("94"))] || "");
    console.log(props.data);
    
  }, [props.data]); // Run this effect whenever props.data changes

  const allDropdownsFilled = input1 && input2 && input3 && input4;

  const handleInputChange = (e: SelectChangeEvent<string>, setInput: React.Dispatch<React.SetStateAction<string>>) => {
    //console.log(props.dropdownset)
 
    //console.log(e.target.value);
    setInput(e.target.value);
    if(e.target.value===""){
      props.updateAnswer2([]);
    }
   

    
    else if(!(props.data.some(str => str.includes(getSubstringUpToSpace(e.target.value)))) && (e.target.value!=="") ){
      
      props.data.push(e.target.value);
      if(props.data.length===4)
        props.updateAnswer2(props.data);
        
      console.log(props.data);
    }
    else if((findSubstringIndex(props.data,getSubstringUpToSpace(e.target.value)))>-1){
      const oldData = [...props.data];
      oldData[findSubstringIndex(props.data,getSubstringUpToSpace(e.target.value))]=e.target.value;
      props.updateAnswer2(oldData);
      //console.log(props.data);
     
    }
    //console.log(props.data[1])
   // // if (props.data.includes(e.target.value)) {
    //   props.updateAnswer2([]);
    // } else {
    //   props.updateAnswer2([e.target.value]);
    // }
  };

 



  const getSubstringUpToSpace = function(mainString: string) {
    // Find the index of the first space
    const spaceIndex = mainString.indexOf(' ');
    
    // If spaceIndex is -1, it means there's no space in the string
    // In that case, return the whole string
    if (spaceIndex === -1) {
        return mainString;
    }
    
    // Return the substring from index 0 to spaceIndex
    return mainString.substring(0, spaceIndex);
};


const findSubstringIndex = function(array: string[], substring: string) {
  for (let i = 0; i < array.length; i++) {
    if (getSubstringUpToSpace(array[i]).includes(substring)) {
      return i; // Return the index if substring is found
    }
  }
  return -1; // Return -1 if substring is not found in any string
}





  return (
    <Grid sx={{ width: "100%" }}>
      <Grid container item justifyContent="center">
        <div style={{ display: 'flex', alignItems: 'center', padding: '0px 30px 20px 0px' }}>
          <Typography
            variant="h4"
            sx={{ color: "black", padding: "0 0px 0 34px 0" }}
          >
            請輸入你的個人資訊:
          </Typography>
        </div>
      </Grid>

      <Grid container justifyContent="center">
        <form >
          <Grid container spacing={2}>
            {/* Input 1 */}
            <Grid item xs={6} container alignItems="center">
              <Typography variant="h6" sx={{ color: 'black', paddingRight: '20px', flex: '0 0 auto', fontSize: '24px' }}>
                年齡
              </Typography>
              <Select
                labelId="dropdown1-label"
                id="dropdown1"
                value={input1 
              }
              
                onChange={(e) =>  handleInputChange(e, setInput1) }
                displayEmpty
                style={{ width: '180px', flex: '1', borderRadius: '12px' }} // Adjusted width and added border radius
              >
                <MenuItem value="">
                  <em>請選擇</em>
                </MenuItem>
                
                {props.dropdownset && props.dropdownset.filter(item => item.category_id === 2).map((item, index) => (
                  <MenuItem key={index} value={91+" "+item.option_name}>{item.option_name}  </MenuItem>
                ))}
              </Select>
            </Grid>

            {/* Input 2 */}
            <Grid item xs={6} container alignItems="center">
              <Typography variant="h6" sx={{ color: 'black', paddingRight: '20px', flex: '0 0 auto', fontSize: '24px' }}>
                性別
              </Typography>
              <Select
                labelId="dropdown2-label"
                id="dropdown2"
                value={input2}
                onChange={(e) => handleInputChange(e, setInput2)}
                displayEmpty
                style={{ width: '180px', flex: '1', borderRadius: '12px' }} // Adjusted width and added border radius
              >
                <MenuItem value="">
                  <em>請選擇</em>
                </MenuItem>
                {props.dropdownset && props.dropdownset.filter(item => item.category_id === 3).map((item, index) => (
                  <MenuItem key={index} value={92+" "+item.option_name}>{item.option_name}</MenuItem>
                ))}
              </Select>
            </Grid>

            {/* Input 3 */}
            <Grid item xs={12} container alignItems="center">
              <Typography variant="h6" sx={{ color: 'black', paddingRight: '20px', flex: '0 0 auto' }}>
                工作狀況
              </Typography>
              <Select
                labelId="dropdown3-label"
                id="dropdown3"
                value={input3}
                onChange={(e) => handleInputChange(e, setInput3)}
                displayEmpty
                style={{ width: '400px', flex: '1', borderRadius: '12px' }} // Adjusted width and added border radius
              >
                <MenuItem value="">
                  <em>請選擇</em>
                </MenuItem>
                {props.dropdownset && props.dropdownset.filter(item => item.category_id === 4).map((item, index) => (
                  <MenuItem key={index} value={93+" "+item.option_name}>{item.option_name}</MenuItem>
                ))}
              </Select>
            </Grid>

            {/* Input 4 */}
            <Grid item xs={12} container alignItems="center">
              <Typography variant="h6" sx={{ color: 'black', paddingRight: '20px', flex: '0 0 auto' }}>
                你現時有沒有領取任何社區或經濟資助?
              </Typography>
              <Select
                labelId="dropdown4-label"
                id="dropdown4"
                value={input4}
                onChange={(e) => handleInputChange(e, setInput4)}
                displayEmpty
                style={{ width: '180px', flex: '1', borderRadius: '12px' }} // Adjusted width and added border radius
              >
                <MenuItem value="">
                  <em>請選擇</em>
                </MenuItem>
                {props.dropdownset && props.dropdownset.filter(item => item.category_id === 5).map((item, index) => (
                  <MenuItem key={index} value={94+" "+item.option_name}>{item.option_name}</MenuItem>
                ))}
              </Select>
            </Grid>

           
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default PersonalInfoForm;
