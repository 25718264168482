import _ from "lodash";
import axios from "axios";
import { toast } from "react-toastify";
import ReactGA from "react-ga4";
import { ExceptionType } from "../constants/ga";
import toastIds from "../constants/toastIds";

const handleRequestErr = (err: any) => {
  if (axios.isAxiosError(err)) {
    if (err.response?.status === 401) {
      const errMsg = _.get(err, "response.data.msg", null);
      if (typeof errMsg === "string") {
        toast.error(errMsg, {
          toastId: toastIds.UNAUTHORIZED,
        });
      } else {
        toast.error("Unauthorized", {
          toastId: toastIds.UNAUTHORIZED2,
        });
      }
    } else if (err.code === "ERR_NETWORK") {
      toast.error(err.message, {
        toastId: toastIds.NETWORK_ERROR,
      });
    } else if (err.response?.status === 400) {
      const errMsg = _.get(err, "response.data", null);
      if (Array.isArray(errMsg)) {
        for (let i = 0; i < Math.min(errMsg.length, 2); i++) {
          toast.error(errMsg[i]?.msg ?? "", {});
        }
      } else {
        toast.error("Bad Request", {
          toastId: toastIds.BADREQUEST,
        });
      }
    } else if (err.response?.status === 403) {
      const errMsg = _.get(err, "response.data.msg", null);
      if (typeof errMsg === "string") {
        toast.error(errMsg, {
          toastId: toastIds.FORBIDDEN,
        });
      } else {
        toast.error("Forbidden", {
          toastId: toastIds.FORBIDDEN2,
        });
      }
    } else if (err.response?.status === 500) {
      toast.error("Interal Server Error", {
        toastId: toastIds.INTERNAL_SERVER_ERROR,
      });
    } else if (err.response?.status === 409) {
      const errMsg = _.get(err, "response.data.msg", null);
      if (typeof errMsg === "string") {
        toast.error(errMsg, {
          toastId: toastIds.CONFLICTS,
        });
      } else {
        toast.error("Conflicts", {
          toastId: toastIds.CONFLICTS2,
        });
      }
    }

    ReactGA.send({
      type: ExceptionType.Axios,
      url: err.config?.url,
      status: err.response?.status,
      error: err,
      description: `${err.config?.url}: ${err.name} - ${err.message}`,
      fatal: true,
    });

    return {
      success: false,
    };
  }

  ReactGA.send({
    type: ExceptionType.UnClassifed,
    description: err,
    fatal: true,
  });
  toast.error("Unexpected Error", {
    toastId: toastIds.UNEXPECTED_ERROR,
  });
  return {
    success: false,
  };
};

export default handleRequestErr;
