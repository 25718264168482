import React, { useState } from 'react';
import { Grid, useTheme, styled, Typography, Checkbox, FormControlLabel } from "@mui/material";
import BaseButton from "../shareComponents/button/BaseButton";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import DisclaimerDrawer from "../shareComponents/drawer/DisclaimerDrawer";
import { useQuery } from "@tanstack/react-query";
import { getTheme } from "../../services/theme";
import REACT_QUERY_KEYS from "../../utils/constants/reactQueryKeys";
import { useLocation } from "react-router-dom";
import PICS from '../shareComponents/drawer/PICS';


interface WelcomeTestProps {
 page: number;
 setPage: (page: number) => void;
}


const weCareBill_logo =
 "https://cancer101-public.s3.ap-southeast-1.amazonaws.com/supportedOrganisation_logo/weCareBill_logo.png";
const philanthropicCommunityPharmacy_logo =
 "https://cancer101-public.s3.ap-southeast-1.amazonaws.com/supportedOrganisation_logo/philanthropicCommunityPharmacy_logo.png";
const pharmaceutical_logo =
 "https://cancer101-public.s3.ap-southeast-1.amazonaws.com/supportedOrganisation_logo/pharmaceutical_logo.png";
const charityFoundation_logo =
 "https://cancer101-public.s3.ap-southeast-1.amazonaws.com/supportedOrganisation_logo/charityFoundation_logo.png";
const cancerPatientAliance_logo =
 "https://cancer101-public.s3.ap-southeast-1.amazonaws.com/supportedOrganisation_logo/cancerPatientAliance_logo.png";


const supportedOrganisation_logo_array = [
 charityFoundation_logo,
 cancerPatientAliance_logo,
 pharmaceutical_logo,
 philanthropicCommunityPharmacy_logo,
 weCareBill_logo,
];


const WelcomeTest = (props: WelcomeTestProps) => {
 const [checked, setChecked] = useState(false);
 const [checked2, setChecked2] = useState(false);
 const theme = useTheme();


 const location = useLocation();
 const { data: themeData, isLoading } = useQuery(
   [REACT_QUERY_KEYS.DB_PUBLIC_THEME_DATA],
   () => getTheme(location.pathname),
   {
     enabled: true,
     retry: true,
   },
 );
 const cancer_logo = themeData?.data?.icon;


 const title = "免責聲明";
 const title2 = "個人資料收集聲明";
 const Item = styled(Grid)(({ theme }) => ({
   padding: "15px 0px 15px 0px",
   textAlign: "center",
   color: theme.palette.primary.main,
 }));


 const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
   setChecked(event.target.checked);
 };

 const handleCheckboxChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
  setChecked2(event.target.checked);
};


 return (
   <>
     <Grid
       sx={{
         margin: {
           xs: "0 5% 0 5%",
           md: "0 10% 0 10%",
           lg: "0 20% 0 20%",
         },
       }}
     >
       <Grid
         container
         item
         xs={12}
         sx={{
           paddingBottom: {
             xs: 1,
             md: 3,
             lg: 3,
           },
         }}
       >
         <Grid
           container
           item
           xs={12}
           justifyContent="center"
           sx={{
             paddingTop: {
               xs: 2,
               md: 4,
               lg: 4,
             },
           }}
         >
           <img
             src={cancer_logo}
             style={{
               paddingTop: 25,
               height: 90,
             }}
           />
         </Grid>
         <Grid fontWeight="900" fontSize="2.2rem" item xs={12}>
           <Item>癌症101計劃 – 為癌症患者領航</Item>
         </Grid>
         <Grid fontSize="1.2rem" item xs={12}>
           <Item>
             這個癌症支援平台旨在為肺癌患者<b>尋找及配對可信的社區支援</b>。
           </Item>
         </Grid>
         <Grid fontSize="1.2rem" item xs={12}>
           <Item>
             只要簡單回答幾個問題，系統即時爲你推介適合你的資訊、財政、心理、生理和
           </Item>
         </Grid>
         <Grid fontSize="1.2rem" item xs={12}>
           <Item>
           日常的支援服務。
           </Item>
         </Grid>
       </Grid>
       <Grid
         borderTop="1px solid #DEDAD5"
         container
         sx={{
           paddingTop: {
             xs: 1.5,
             md: 4,
             lg: 4,
           },
         }}
       >
         <Grid
           container
           item
           direction="column"
           justifyContent="center"
           alignItems="center"
           xs={12}
           sx={{ color: "black" }}
         >
           <Grid
             sx={{
               paddingBottom: {
                 xs: 1.5,
                 md: 3,
                 lg: 3,
               },
             }}
           >
             <Typography>
             在進行快測前，請你閱讀並同意以下
               <DisclaimerDrawer title={title} color="orange" />


               及
               <PICS title={title2} color="orange" />。
             </Typography>
           </Grid>


           <Grid
             container
             justifyContent="center"
             sx={{
               paddingBottom: 1,
               display: "flex",
               alignItems: "flex-start",
               flexFlow: "row nowrap",
             }}
           >
             <FormControlLabel
               control={
                 <Checkbox
                   checked={checked}
                   onChange={handleCheckboxChange}
                   name="disclaimer"
                 />
               }
               label={
                 <Typography sx={{ textAlign: "start" }}>
                   我已閱讀及同意免責聲明。
                 </Typography>
               }
             />
             
           </Grid>
           <Grid
             container
             justifyContent="center"
             sx={{
               paddingBottom: 1,
               display: "flex",
               alignItems: "flex-start",
               flexFlow: "row nowrap",
             }}
           >
             <FormControlLabel
               control={
                 <Checkbox
                   checked={checked2}
                   onChange={handleCheckboxChange2}
                   name="disclaimer"
                 />
               }
               label={
                 <Typography sx={{ textAlign: "start" }}>
                   我已閱讀及同意個人資料收集聲明。
                 </Typography>
               }
             />
             
           </Grid>
         </Grid>


        





         <Grid item xs={12}>
           <Item>
             <BaseButton
               onClick={() => {
                 props.setPage(1);
               }}
               disabled={!checked || !checked2}
             >
               開始
               <KeyboardArrowRightIcon />
             </BaseButton>
           </Item>
         </Grid>
       </Grid>

     

       <Grid sx={{ marginTop: 4 }}>
         <Typography sx={{ fontWeight: 700 }}>支持機構:</Typography>
       </Grid>


       {/* supported organisation list */}
       <Grid container item display="flex" justifyContent="space-around">
         {supportedOrganisation_logo_array.map((link, idx) => (
           <Grid
             key={idx}
             sx={{
               backgroundImage: `url(${link})`,
               width: "8rem",
               height: "8rem",
               backgroundPosition: "center",
               backgroundRepeat: "no-repeat",
               backgroundSize: "contain",
               position: "relative",
             }}
           ></Grid>
         ))}
       </Grid>


       {/*  */}
     </Grid>
   </>
 );
};


export default WelcomeTest;




