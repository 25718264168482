import * as React from "react";
import Drawer from "@mui/material/Drawer";
import { Grid, Typography } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

type Anchor = "bottom";

interface TermsAndConditionDrawerProps {
  title: string;
}

const TermsAndConditionDrawer = (props: TermsAndConditionDrawerProps) => {
  const [state, setState] = React.useState({
    bottom: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  return (
    <>
      {(["bottom"] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <Typography
            component={"span"}
            onClick={toggleDrawer(anchor, true)}
            sx={{
              color: "#083FC9",
              display: "inline-block",
              textDecoration: "underline",
              "&:hover": {
                textDecoration: "underline",
                cursor: "pointer",
              },
            }}
          >
            {props.title}
          </Typography>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            <Grid
              sx={{
                padding: {
                  xs: "3% 5% 0 5%",
                  md: "3% 10% 0 10%",
                  lg: "3% 20% 0 20%",
                },
              }}
            >
              <Grid container justifyContent="space-between">
                <Typography
                  variant="h5"
                  sx={{ color: "black", fontWeight: 900, paddingBottom: 2 }}
                >
                  癌症支援平台免責聲明
                </Typography>
                <CloseRoundedIcon
                  sx={{
                    fontSize: 40,
                    color: "#706B69",
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                  onClick={toggleDrawer(anchor, false)}
                />
              </Grid>
              <Typography
                variant="h6"
                sx={{ fontWeight: 700, paddingBottom: 0.3 }}
              >
                Terms of Service
              </Typography>
              <Typography variant="subtitle1" sx={{ paddingBottom: 6 }}>
                I hereby acknowledge that I have voluntarily disclosed the
                information written on this Follow-up Request (which includes my
                personal contact information) to Roche Hong Kong Limited and its
                affiliates. I hereby consent to Roche Hong Kong Limited and its
                affiliates: a) to contact me via phone call for the purposes of
                Cancer 101 program, pharmacovigilance report follow up. b)
                retaining the information written on this Resources Matching
                Self-Assessment. I acknowledge that Roche will comply with
                relevant privacy legislation i.e. including but not limited to
                the Personal Data (Privacy) Ordinance, Cap 486. and take
                appropriate precautions to protect my personal information.
              </Typography>

              <Typography
                variant="h6"
                sx={{ fontWeight: 700, paddingBottom: 0.3 }}
              >
                服務條款
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ paddingBottom: 3, borderBottom: "1px solid #DBD6D1" }}
              >
                本人在此確認本人是自願向羅氏大藥廠及其附屬公司提供所有在此《癌症支援平台》中的資料(包括本人的個人聯絡資料)。我在此同意羅氏大藥廠及其附屬公司:
                a) 就癌症101計劃和藥物不良事件報告的跟進，通過電話與我聯繫。 b)
                保留此《癌症支援平台》上的資料。我相信羅氏大藥廠將遵守相關的個人私隱法例，即包括但不限於《個人資料（私隱）條例》，第
                486 章，並採取適當的預防措施來保護我的個人資料。
              </Typography>

              <Typography
                variant="h6"
                sx={{ fontWeight: 700, paddingBottom: 0.3, paddingTop: 3 }}
              >
                Safety Reporting Disclaimer
              </Typography>
              <Typography variant="subtitle1" sx={{ paddingBottom: 6 }}>
                This program is neither intended nor designed to record or
                report adverse event information. If you have a suspected side
                effect or problem to report regarding one of our products,
                please discuss with your treating Healthcare Professional and
                send the information to the Roche Patient Safety team via: (a)
                Email: hong_kong.drug_safety@roche.com or (b) Phone: +852 2733
                4711. Roche has legal obligation to collect and report potential
                adverse events brought to its attention to Health Authorities.
                In such cases, your data will be processed with greatest care
                and diligence in accordance with specific GVP
                (Pharmacovigilance) legislation, as described in the Privacy
                Policy related to pharmacovigilance. The personal data collected
                during this program will be solely used for the purpose
                consented in this Form. To learn more about Roche privacy
                policy, please visit: https://www.roche.com.hk/en/privacy.html.
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontWeight: 700, paddingBottom: 0.3 }}
              >
                安全報告免責聲明
              </Typography>
              <Typography variant="subtitle1" sx={{ paddingBottom: 6 }}>
                本計劃並不旨在用於記錄或報告不良藥物事件資訊。如您懷疑有任何副作用，或有任何與我們產品相關的問題，請向您的醫護人員諮詢，及將有關資訊發送到羅氏大藥廠藥物安全部門:
                (a) 電郵: hong_kong.drug_safety@roche.com, 或 (b) 電話: +852
                2733
                4711。羅氏有法律義務收集並向有關的政府部門報告其藥物潛在的不良事件。在這種情況下，您的個人資料將根據藥物警戒相關的隱私政策中描述的特定GVP
                (藥物警戒)
                法例，以最謹慎的方式處理。本計劃期間收集的個人數據將僅用於本表格中同意的目的。欲了解更多羅氏隱私政策，請游覽：
                https://www.roche.com.hk/en/privacy.htm
              </Typography>
            </Grid>
          </Drawer>
        </React.Fragment>
      ))}
    </>
  );
};

export default TermsAndConditionDrawer;
