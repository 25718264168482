import { Chip, Grid, SxProps, useTheme, Typography } from "@mui/material";
import React, { useState } from "react";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";

interface FilterTagProps {
  name: string;
  color: string;
  selectedCategory: string[];
  setSelectedCategory: React.Dispatch<React.SetStateAction<string[]>>;
}

const FilterTag = (props: FilterTagProps) => {
  const array: string[] = [];
  const theme = useTheme();
  const [state, setState] = useState(true);

  const defaultBtn: SxProps = {
    paddingRight: 2,
    paddingLeft: 2,
    marginTop: 1,
    height: 35,
    backgroundColor: props.color,
    minWidth: 80,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 1000,
    marginLeft: 1,
    color: "black",
  };
  const disableBtn: SxProps = {
    paddingRight: 2,
    paddingLeft: 2,
    marginTop: 1,
    height: 35,
    backgroundColor: theme.palette.grey[300],
    minWidth: 80,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 1000,
    marginLeft: 1,
    color: theme.palette.grey[600],
  };

  return (
    <Grid
      sx={{
        ...(state ? defaultBtn : disableBtn),
        ":hover": {
          cursor: "pointer",
        },
      }}
      onClick={() => {
        if (state === true) {
          setState(!state);
          props.setSelectedCategory((val) => {
            let valClone: string[] = [...val];
            valClone.splice(valClone.indexOf(props.name), 1);
            return valClone;
          });
        }
        if (state === false) {
          setState(true);
          props.setSelectedCategory((val) => {
            let valClone = [...val];
            valClone.push(props.name);
            return valClone;
          });
        }
      }}
    >
      {/* {state ? <DoneRoundedIcon /> : ""} */}
     <Typography>{props.name}</Typography> 
    </Grid>
  );
};

export default FilterTag;
