import { CategoryType, LabelType } from "../utils/constants/ga";
import { API_ROUTE_FULL_PATHS } from "../utils/constants/routes/apiPaths";
import handleRequestErr from "../utils/helperFunctions/handleRequestErr";
import { UserAnswerProps } from "../utils/types/services/userAnswer";
import { UserResult } from "../utils/types/services/userResult";
import api from "./api";
import ReactGA from "react-ga4";
import { AxiosResponse } from "axios";

const handleRequestFinally = (result: AxiosResponse | null) => {
  if (result === null) return;
  ReactGA.event({
    category: CategoryType.Axios,
    action: result.config.url ?? "",
    label: LabelType.submittedUserAnswer,
    value: result.status,
  });
};

export const addUserAnswer = async (
  userAnswer: UserAnswerProps,
  currentCancerType: string,
): Promise<{ result: UserResult | undefined }> => {
  let result = null;
  try {
    result = await api.post(
      `${API_ROUTE_FULL_PATHS.testFormPostUserAnswer}${currentCancerType}`,
      userAnswer,
    );
    if (result.status === 200) {
      return {
        result: result.data,
      };
    }

    throw new Error("no other success status will be returned excpet 200");
  } catch (err) {
    handleRequestErr(err);
    throw err;
  } finally {
    handleRequestFinally(result);
  }
};
