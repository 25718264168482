import { useTheme, SxProps } from "@mui/material";

const useButtonCSSHook = () => {
  const theme = useTheme();

  const selectedBtn: SxProps = {
    backgroundColor: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.secondary.main}`,
    color: "theme.palette.primary.main",
    // width: "100%",
  };
  const defaultBtn = {
    backgroundColor: "#FFFFFF",
    border: `1px solid ${theme.palette.primary.main}`,
    color: "theme.palette.primary.main",
    // width: "100%",
  };

  return {
    selectedBtn,
    defaultBtn,
  };
};

export default useButtonCSSHook;
