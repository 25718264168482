import {
  Box,
  Chip,
  Grid,
  Modal,
  TextField,
  Typography,
  useTheme,
  Tooltip,
} from "@mui/material";
import React from "react";
import Link from "@mui/material/Link";
import StarsRoundedIcon from "@mui/icons-material/StarsRounded";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import QueryBuilderRoundedIcon from "@mui/icons-material/QueryBuilderRounded";
import AssistantDirectionRoundedIcon from "@mui/icons-material/AssistantDirectionRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ShareRoundedIcon from "@mui/icons-material/ShareRounded";
import InsertLinkRoundedIcon from "@mui/icons-material/InsertLinkRounded";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import ReactGA from "react-ga4";
import { ActionType, CategoryType } from "../../utils/constants/ga";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import {
  FacebookShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  LineShareButton,
  EmailShareButton,
} from "react-share";
import {
  FacebookIcon,
  WhatsappIcon,
  TelegramIcon,
  LineIcon,
  EmailIcon,
} from "react-share";

import { toast } from "react-toastify";
import cancerTypes from "../../utils/constants/cancerTypes";

interface OrganisationCardProps {
  name: string;
  service: string;
  address: string;
  contact_number: string;
  opening_hour: string;
  google_map_link: string;
  website: string;
  participationform:string;
  chipsTypes: { name: string; color: string }[];
}

const OrganisationCard = (props: OrganisationCardProps) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const isSpecificOrganization = props.name === "香港大學 「非小細胞肺癌精準醫學計劃」"; 


  const theme = useTheme();

  return (
    <Grid
      item
      xs={12}
      sx={{
        padding: 3,
        border: `2px solid #D3EDEF`,
        margin: 1,
      }}
    >
        <Typography variant="h4" sx={{ paddingBottom: 2 }}>
          {props.name}
        </Typography>
      <Grid container item direction="column" sx={{ paddingBottom: 1 }}>
        <Grid container item alignItems="center">
          <StarsRoundedIcon sx={{ paddingRight: 0.4, alignSelf: "center" }} />
          <Typography variant="h6">服務範疇：</Typography>
        </Grid>
        <Grid sx={{ paddingLeft: 3.4 }}>
          <Typography variant="h6">{props.service}</Typography>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          paddingBottom: 1,
          display: "flex",
          alignItems: "flex-start",
          flexFlow: "row nowrap",
        }}
      >
        <Grid sx={{ paddingTop: "2px" }}>
          <LocationOnRoundedIcon sx={{ color: "#69C8B2", paddingRight: 0.4 }} />
        </Grid>
        <Typography variant="h6" sx={{ color: "black" }}>
          地址：{props.address}
        </Typography>
      </Grid>

      <Grid
        container
        sx={{
          paddingBottom: 1,
          display: "flex",
          alignItems: "flex-start",
          flexFlow: "row nowrap",
        }}
      >
        <Grid sx={{ paddingTop: "2px" }}>
          <LocalPhoneRoundedIcon sx={{ color: "#69C8B2", paddingRight: 0.4 }} />
        </Grid>
        <Typography variant="h6" sx={{ color: "black" }}>
          電話：
          {props.contact_number == "不適用" ? (
            <Typography component="span" variant="h6">
              不適用
            </Typography>
          ) : (
            <Link href={`tel:${props.contact_number}`}>
             <Typography component="span" variant="h6">{props.contact_number}</Typography> 
            </Link>
          )}
        </Typography>
      </Grid>

      {props.participationform !== "none" && (
  <Grid
    container
    sx={{
      paddingBottom: 1,
      display: "flex",
      alignItems: "flex-start",
      flexFlow: "row nowrap",
    }}
  >
    <Grid sx={{ paddingTop: "2px" }}>
      <DescriptionRoundedIcon sx={{ color: "#69C8B2", paddingRight: 0.4 }} />
    </Grid>
    <Typography variant="h6" sx={{ color: "black" }}>
      <a href={props.participationform} target="_blank" rel="noreferrer" style={{ textDecoration: 'underline' }}>
        參加表格
      </a>
    </Typography>
  </Grid>
)}


      <Grid
        container
        sx={{
          paddingBottom: 1,
          display: "flex",
          alignItems: "flex-start",
          flexFlow: "row nowrap",
        }}
      >
        <Grid sx={{ paddingTop: "2px" }}>
          <QueryBuilderRoundedIcon
            sx={{ color: "#69C8B2", paddingRight: 0.4 }}
          />
        </Grid>
        <Typography variant="h6" sx={{ color: "black" }}>
          開放時間：{props.opening_hour}
        </Typography>
      </Grid>
      <Box>
        <Grid
          container
          item
          sx={{
            paddingTop: 4,
            paddingBottom: 4,
          }}
        >
          {props.address == "只提供網上服務" ? (
            ""
          ) : (
            <Grid item sx={{ alignItems: "end", paddingRight: 3 }}>
              <Link
                target="_blank"
                underline="hover"
                href={`https://${props.google_map_link}`}
              >
                <Typography
                  variant="h6"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <AssistantDirectionRoundedIcon
                    sx={{
                      color: "#69C8B2",
                      paddingRight: 0.5,
                      alignSelf: "center",
                    }}
                  />
                  如何前往
                </Typography>
              </Link>
            </Grid>
          )}

          <Grid
            item
            sx={{
              alignItems: "end",
              paddingRight: 3,
              "&:hover": {
                textDecoration: "underline",
                cursor: "pointer",
              },
            }}
            onClick={handleOpen}
          >
            <Typography
              variant="h6"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <ShareRoundedIcon
                sx={{
                  color: "#69C8B2",
                  paddingRight: 0.5,
                  alignSelf: "center",
                }}
              />
              分享給朋友
            </Typography>
          </Grid>
          <Grid item sx={{ alignItems: "end", paddingRight: 3 }}>
            <Link
              target="blank"
              underline="hover"
              href={`https://${props.website}`}
              onClick={() => {
                ReactGA.event({
                  category: `clicked_organisation_website_${cancerTypes[0]}_NGO_${props.name}`,
                  action: `clicked_organisation_website_${cancerTypes[0]}_NGO_${props.name}`,
                });
              }}
            >
              <Typography
                variant="h6"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <InsertLinkRoundedIcon
                  sx={{ color: "#69C8B2", paddingRight: 0.5 }}
                />
                網頁連結
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </Box>
      <Grid container item xs={12} justifyContent="end">
        {props.chipsTypes.map((item, index) => {
          return (
            <Chip
              key={index}
              label={item.name}
              sx={{
                backgroundColor: item.color,
                color: "black",
                padding: 1,
                marginLeft: 1,
                marginTop: 1,
              }}
            />
          );
        })}
      </Grid>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: 350,
            // minHeight: 170,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            padding: 0,
          }}
        >
          <Grid
            sx={{
              borderBottom: "1px #E0E0E0 solid",
              display: "flex",
              justifyContent: "space-between",
              padding: "0 1rem",
              height: "3rem",
              alignItems: "center",
              backgroundColor: "FFFFFF",
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              分享給朋友
            </Typography>
            <CloseRoundedIcon
              onClick={handleClose}
              sx={{ cursor: "pointer" }}
            />
          </Grid>
          <Grid
            sx={{
              padding: "1rem",
              backgroundColor: "#F6F6F6",
              borderRadius: 2,
            }}
          >
            <Grid
              sx={{
                border: "1px solid #D9D9D9",
                borderRadius: "4px",
                padding: "0.4rem",
                margin: "0.4rem",
                backgroundColor: "#FFFFFF",
              }}
            >
              <Typography>{props.name}</Typography>
              <Typography>服務範疇：{props.service}</Typography>
              <Typography>- 分享自Cancer 101</Typography>
              <Typography>網頁連結：{props.website}</Typography>
            </Grid>
            <Grid
              container
              item
              sx={{
                display: "flex",
                flexDirection: "row",
                overflow: "auto",
                padding: "1rem 0.5rem",
                justifyContent: "center",
              }}
            >
              <Tooltip title="Copy">
                <Grid
                  sx={{
                    width: 50,
                    height: 50,
                    margin: "0 0.65rem 0 0",
                    backgroundColor: "#7A7A7A",
                    borderRadius: 100,
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${props.name}\n服務範疇：${props.service}\n網頁連結：${props.website}\n分享來自Cancer 101`,
                    );
                    toast.success("Copied");
                  }}
                >
                  <FileCopyIcon
                    sx={{
                      color: "#FFFFFF",
                      fontSize: 27,
                    }}
                  />
                </Grid>
              </Tooltip>
              <Tooltip title="Whatsapp">
                <Grid>
                  <WhatsappShareButton
                    url={props.website}
                    title={props.name}
                    separator={`\n服務範疇：${props.service}\n`}
                    style={{ margin: "0 0.65rem 0 0" }}
                  >
                    <WhatsappIcon size={50} round={true} />
                  </WhatsappShareButton>
                </Grid>
              </Tooltip>
              <Tooltip title="Email">
                <Grid>
                  <EmailShareButton
                    url={props.website}
                    subject={props.name}
                    body={`${props.name}\n服務範疇：${props.service}\n網頁連結：${props.website}`}
                    style={{ margin: "0 0.65rem 0 0" }}
                  >
                    <EmailIcon size={50} round={true} />
                  </EmailShareButton>
                </Grid>
              </Tooltip>
              <Tooltip title="Facebook">
                <Grid>
                  <FacebookShareButton
                    url={props.website}
                    quote={`${props.name}\n服務範疇：${props.service}\n網頁連結：${props.website}`}
                    style={{ margin: "0 0.65rem 0 0" }}
                  >
                    <FacebookIcon size={50} round={true} />
                  </FacebookShareButton>
                </Grid>
              </Tooltip>
              <Tooltip title="Line">
                <Grid>
                  <LineShareButton
                    url={`https://${props.website}`}
                    title={`${props.name}\n服務範疇：${props.service}\n\n`}
                    style={{ margin: "0 0.65rem 0 0" }}
                  >
                    <LineIcon size={50} round={true} />
                  </LineShareButton>
                </Grid>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Grid>
  );
};

export default OrganisationCard;
