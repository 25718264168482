import axios from "axios";

const api = axios.create({
  withCredentials: process.env.NODE_ENV === "production" ? undefined : true,
  baseURL:
    process.env.NODE_ENV === "production"
      ? undefined
      : process.env.REACT_APP_API_URL,
  timeout: process.env.NODE_ENV === "production" ? 15000 : 60000,
});

export default api;
