import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useTheme,
  Link,
 } from "@mui/material";
 import React, { useCallback, useRef, useState } from "react";
 import BaseButton from "../shareComponents/button/BaseButton";
 import { useForm, Controller, SubmitHandler } from "react-hook-form";
 import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
 import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
 import SelectTimeButton from "../shareComponents/button/SelectTimeButton";
 import useButtonCSSHook from "../FollowUpForm/ButtonCSSHook";
 import TaskRoundedIcon from "@mui/icons-material/TaskRounded";
 import TermsAndConditionDrawer from "../shareComponents/drawer/TermsAndConditionDrawer";
 import { UserAnswerProps } from "../../utils/types/services/userAnswer";
 import { addUserContact } from "../../services/userContact";
 import { useMutation, useQuery } from "@tanstack/react-query";
 import { UserContactProps } from "../../utils/types/services/userContact";
 import { literal, number, object, string, TypeOf, union } from "zod";
 import { zodResolver } from "@hookform/resolvers/zod";
 import { UserResult } from "../../utils/types/services/userResult";
 import ReactGA from "react-ga4";
 import cancerTypes from "../../utils/constants/cancerTypes";
 import { ActionType, CategoryType } from "../../utils/constants/ga";
 import { useLocation } from "react-router-dom";
 import { getTheme } from "../../services/theme";
 import REACT_QUERY_KEYS from "../../utils/constants/reactQueryKeys";
 
 
 const dayTimeSlot: {
  name: string;
  value: "monday" | "tuesday" | "wednesday" | "thursday" | "friday";
 }[] = [
  {
    name: "星期一",
    value: "monday",
  },
  {
    name: "星期二",
    value: "tuesday",
  },
  {
    name: "星期三",
    value: "wednesday",
  },
  {
    name: "星期四",
    value: "thursday",
  },
  {
    name: "星期五",
    value: "friday",
  },
 ];
 
 
 const hourTimeSlot: {
  name: string;
  value: "morning" | "afternoon";
 }[] = [
  {
    name: "早上(9am - 12pm)",
    value: "morning",
  },
  {
    name: "下午(2pm - 5pm)",
    value: "afternoon",
  },
 ];
 
 
 const title = "服務條款";
 
 
 interface FollowUpFormProps {
  page: number;
  data: UserAnswerProps;
  setData: (value: React.SetStateAction<UserAnswerProps>) => void;
  setPage: (page: number) => void;
  userResult: UserResult | undefined;
  setUserResult: (value: UserResult | undefined) => void;
 }
 
 
 const FollowUpForm = (props: FollowUpFormProps) => {
  const theme = useTheme();
  const location = useLocation();
  const currentCancerType = location.pathname;
  const { data: themeData } = useQuery(
    [REACT_QUERY_KEYS.DB_PUBLIC_THEME_DATA],
    () => getTheme(location.pathname),
    {
      enabled: true,
      retry: true,
    },
  );
  const cancer_logo = themeData?.data?.icon;
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 100,
    p: 4,
  };
 
 
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    props.setPage(0);
    props.setData({
      form1: [],
      form2: [],
      form3: [],
      form4: [],
      form5: [],
      form6: [],
      form7: [],
      form8: [],
      form9: [],
      form10: [],
    });
    props.setUserResult({
      filterTagResult: [],
      organisationResult: [],
      userId: undefined,
    });
  };
  const [isChecked, setIsChecked] = useState<boolean>(false);
 
 
  const handleChecked = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsChecked((val) => !val);
    },
    [],
  );
 
 
  const userContactSchema = object({
    name: string()
      .min(1, { message: "請輸入正確的名字" })
      .max(50, { message: "名字輸入超過上限" }),
    emailOrPhone: union([
      string()
        .regex(new RegExp("^[0-9]+$"), {
          message: "請輸入適當的電郵",
        })
        .min(8, { message: "請輸入適當的電話" })
        .max(8, { message: "請輸入適當的電郵" }),
      string().email({ message: "請輸入適當的電郵" }),
    ]),
    gender: string().min(1, { message: "請選擇你的性別" }),
  });
  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<UserContactProps>({
    defaultValues: {
      userId: props.userResult?.userId,
      name: "",
      gender: null,
      emailOrPhone: "",
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      morning: false,
      afternoon: false,
      agreeToReceiveInformation: false,
    },
    mode: "onChange",
    resolver: zodResolver(userContactSchema),
  });
  zodResolver(userContactSchema);
  const startTestData = props.data;
  const userContact = watch();
  const userSubmitResult = { userContact };
 
 
  const { selectedBtn, defaultBtn } = useButtonCSSHook();
 
 
  const { isLoading, isSuccess, mutateAsync } = useMutation(
    () => addUserContact(userSubmitResult, currentCancerType),
    {
      onSuccess: (data) => {
        handleOpen();
      },
      onError: () => {
        alert("there was an error");
      },
    },
  );
 
 
  return (
    <Grid
      sx={{
        paddingLeft: {
          xs: 5,
          sm: 10,
          md: 15,
          lg: 20,
          xl: 25,
        },
        paddingRight: {
          xs: 5,
          sm: 10,
          md: 15,
          lg: 20,
          xl: 25,
        },
      }}
    >
      <Grid
        sx={{
          borderBottom: "1px solid #DBD6D1",
          paddingTop: 3,
          paddingBottom: 3,
        }}
      >
        <Grid
          container
          item
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ paddingBottom: 2, flexFlow: "column" }}
        >
          <img src={cancer_logo} style={{ height: 60, paddingBottom: 5 }} />
          <Typography variant="h4">要求跟進</Typography>
        </Grid>
 
 
        <Grid sx={{ paddingBottom: 1.5 }}>
          <Typography variant="h6">
            
            <Link
              target="_blank"
              underline="hover"
              href={`https://www.lungcancercare.com.hk/%e7%99%8c%e7%97%87101%e5%85%88%e5%b0%8e%e8%a8%88%e5%8a%83-%e7%82%ba%e8%82%ba%e7%99%8c%e6%82%a3%e8%80%85%e5%b0%8e%e8%88%aa/`}
            >
              <b>（按此了解更多）</b>
            </Link>
          </Typography>
        </Grid>
      </Grid>
 
 
      <form
        onSubmit={handleSubmit(async () => {
          const result = await mutateAsync();
          if (result.result) {
            setIsChecked(false);
            reset();
          }
        })}
      >
        <Grid>
          <Grid
            container
            direction="row"
            justifyContent="start"
            item
            sx={{ paddingTop: 3, paddingBottom: 3 }}
          >
            <Typography variant="h6" color="black">
              個人資料
            </Typography>
          </Grid>
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Box sx={{ width: 500, maxWidth: "100%", paddingBottom: 4 }}>
                <TextField
                  {...field}
                  inputProps={{ maxLength: 50 }}
                  error={!!errors.name}
                  helperText={errors.name ? errors.name.message : ""}
                  // required
                  fullWidth
                  id="outlined-required"
                  label="姓名"
                  type="text"
                  autoComplete="name"
                />
              </Box>
            )}
          />
 
 
          <Controller
            name="gender"
            control={control}
            render={({ field }) => (
              <FormControl
                required={true}
                sx={{ paddingBottom: 3 }}
                error={!!errors.gender}
                variant="standard"
              >
                <FormLabel id="demo-error-radios">性別</FormLabel>
                <RadioGroup
                  {...field}
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  // value={!!errors.gender}
                  // helperText={errors.gender ? errors.gender.message : ""}
                >
                  <FormControlLabel
                    value="male"
                    control={<Radio />}
                    label="男"
                    sx={{ color: "black" }}
                  />
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    label="女"
                    sx={{ color: "black" }}
                  />
                </RadioGroup>
                <FormHelperText sx={{ marginLeft: "14px" }}>
                  {errors.gender ? "請選擇你的性別" : ""}
                </FormHelperText>
              </FormControl>
            )}
          />
 
 
          <Controller
            name="emailOrPhone"
            control={control}
            render={({ field }) => (
              <Box sx={{ width: 500, maxWidth: "100%", paddingBottom: 4 }}>
                <TextField
                  {...field}
                  fullWidth
                  id="outlined-required"
                  label="聯絡電話或電郵"
                  error={!!errors.emailOrPhone}
                  helperText={
                    errors.emailOrPhone ? errors.emailOrPhone.message : ""
                  }
                />
              </Box>
            )}
          />
        </Grid>
 
 
        <Grid container item sx={{ paddingBottom: 3 }}>
          <Typography variant="subtitle1" sx={{ color: "black" }}>
            請選擇最方便我們聯絡你的時間
          </Typography>
          <Typography variant="subtitle1" sx={{ color: "#999999" }}>
            (可選擇多於一項)
          </Typography>
        </Grid>
 
 
        <Grid sx={{ borderBottom: "1px solid #DBD6D1", paddingBottom: 5 }}>
          <Grid>
            {dayTimeSlot.map((item, index) => {
              return (
                <SelectTimeButton
                  key={index}
                  onClick={() => {
                    const singleValue = getValues(`${item.value}`);
                    setValue(`${item.value}`, !singleValue, {
                      shouldDirty: true,
                      shouldTouch: true,
                      shouldValidate: true,
                    });
                  }}
                  sx={{
                    ...(watch(`${item.value}`) ? selectedBtn : defaultBtn),
                  }}
                >
                  {item.name}
                </SelectTimeButton>
              );
            })}
          </Grid>
 
 
          <Grid>
            {hourTimeSlot.map((item, index) => {
              return (
                <SelectTimeButton
                  key={index}
                  onClick={() => {
                    const singleValue = getValues(`${item.value}`);
                    setValue(`${item.value}`, !singleValue, {
                      shouldDirty: true,
                      shouldTouch: true,
                      shouldValidate: true,
                    });
                  }}
                  sx={{
                    ...(watch(`${item.value}`) ? selectedBtn : defaultBtn),
                  }}
                >
                  {item.name}
                </SelectTimeButton>
              );
            })}
          </Grid>
 
 
          <Grid
            container
            sx={{
              paddingBottom: 1,
              display: "flex",
              alignItems: "flex-start",
              flexFlow: "row nowrap",
              paddingTop: 4,
            }}
          >
            <Controller
              name="agreeToReceiveInformation"
              control={control}
              render={({ field }) => (
                <>
                  <Checkbox
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={field.value}
                    sx={{
                      display: "flex",
                      alignItems: "start",
                      padding: 0,
                      paddingTop: 0,
                      paddingRight: 1,
                    }}
                  />
                  <Typography color="black">
                    若羅氏大藥廠將來舉辦肺癌患者活動時，你希望收到相關的資訊嗎?
                  </Typography>
                </>
              )}
            />
          </Grid>
        </Grid>
 
 
        <Grid sx={{ paddingTop: 4, paddingBottom: 7 }}>
          <Typography color="black" sx={{ paddingBottom: 2 }}>
            在遞交資料前，請你閱讀並同意以下
            <TermsAndConditionDrawer title={title} />
          </Typography>
 
 
          <Grid
            container
            sx={{
              paddingBottom: 1,
              display: "flex",
              alignItems: "flex-start",
              flexFlow: "row nowrap",
              paddingTop: 4,
            }}
          >
            <Checkbox
              onChange={handleChecked}
              checked={isChecked}
              sx={{
                display: "flex",
                alignItems: "start",
                padding: 0,
                paddingTop: 0,
                paddingRight: 1,
              }}
            />
            <Typography color="black">
              我在此確認，我已完整閱讀並理解述條款和條件。如中英文版本有差異，請以英文版本為準。
            </Typography>
          </Grid>
        </Grid>
 
 
        <Grid container item justifyContent="center" sx={{ paddingBottom: 5 }}>
          <BaseButton
            disabled={isLoading}
            onClick={() => {
              props.setPage(2);
              window.scrollTo(0, 0);
            }}
            type="button"
            sx={{
              backgroundColor: "transparent",
              width: 130,
              marginRight: 1,
              color: `${theme.palette.primary.main}`,
              "&:hover": {
                backgroundColor: "#E8EAED",
              },
            }}
          >
            <KeyboardArrowLeftRoundedIcon />
            返回
          </BaseButton>
          <BaseButton
            disabled={!isChecked || isLoading}
            type="submit"
            sx={{
              width: 130,
              marginLeft: 1,
            }}
            onClick={() => {
              ReactGA.event({
                category: `${cancerTypes[0]}${CategoryType.FollowUpSubmit}`,
                action: ActionType.clickedFollowUp,
              });
            }}
          >
            遞交
            <KeyboardArrowRightRoundedIcon />
          </BaseButton>
        </Grid>
      </form>
 
 
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid
            container
            item
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <TaskRoundedIcon
              sx={{ color: theme.palette.primary.main, fontSize: 65 }}
            />
            <Typography
              variant="h6"
              sx={{ color: theme.palette.primary.main, paddingTop: 3 }}
            >
              遞交完成
            </Typography>
            <Typography variant="subtitle1" sx={{ mt: 2, color: "#666666" }}>
              計劃工作人員將會在兩個工作天內與你聯絡
            </Typography>
            <Typography sx={{ color: "#989898", mt: 2, mb: 2 }}>
              參考編號 #
              {("000000" + props.userResult?.userId).substring(
                ("000000" + props.userResult?.userId).length - 6,
              )}
            </Typography>
            <BaseButton
              onClick={() => {
                window.scrollTo(0, 0);
                props.setPage(0);
              }}
              type="button"
              sx={{
                minWidth: 200,
                maxWidth: 350,
              }}
            >
              明白
            </BaseButton>
          </Grid>
        </Box>
      </Modal>
    </Grid>
  );
 };
 
 
 export default FollowUpForm;
 