import { Grid, useTheme, Box, Link, Typography, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import FilteredSupport from "../FilteredSupport";
import FollowUpForm from "../FollowUpForm";
import StartTest from "../StartTest.tsx";
import WelcomeTest from "../WelcomeTest.tsx";
import { UserResult } from "../../utils/types/services/userResult";
import { UserAnswerProps } from "../../utils/types/services/userAnswer";
import rocheaLogo from "../../images/rocheLogo.png";
import cancer101Logo from "../../images/cancer101_logo.png";
import ReactGA from "react-ga4";
import cancerTypes from "../../utils/constants/cancerTypes";
import InfoIcon from "@mui/icons-material/Info";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import Footer from "../Footer/Page/footer";


interface MainPageProps {}


const MainPage = (props: MainPageProps) => {
 const searchParams = useLocation();


 const [data, setData] = useState<UserAnswerProps>({
   form1: [],
   form2: [],
   form3: [],
   form4: [],
   form5: [],
   form6: [],
   form7: [],
   form8: [],
   form9: [],
   form10: [],
 });


 const updateForm1 = (data: number[]) => {
   setData((val) => ({
     ...val,
     form1: data,
   }));
 };
 const updateForm2 = (data: string[]) => {
   setData((val) => ({
     ...val,
     form2: data,
   }));
 };
 const updateForm3 = (data: string[]) => {
   setData((val) => ({
     ...val,
     form3: data,
   }));
 };
 const updateForm4 = (data: string[]) => {
   setData((val) => ({
     ...val,
     form4: data,
   }));
 };
 const updateForm5 = (data: number[]) => {
   setData((val) => ({
     ...val,
     form5: data,
   }));
 };
 const updateForm6 = (data: string[]) => {
   setData((val) => ({
     ...val,
     form6: data,
   }));
 };


 const [userResult, setUserResult] = useState<UserResult | undefined>({
   filterTagResult: [],
   organisationResult: [],
   userId: undefined,
 });


 const {} = props;
 const theme = useTheme();
 const [page, setPage] = useState(0);
 const pages = [
   <WelcomeTest page={page} setPage={setPage} />,
   <StartTest
     page={page}
     setPage={setPage}
     data={data}
     setData={setData}
     userResult={userResult}
     setUserResult={setUserResult}
   />,
   <FilteredSupport
     page={page}
     setPage={setPage}
     data={data}
     setData={setData}
     userResult={userResult}
     setUserResult={setUserResult}
   />,
   <FollowUpForm
     page={page}
     setPage={setPage}
     data={data}
     setData={setData}
     userResult={userResult}
     setUserResult={setUserResult}
   />,
 ];


 const pageviewCategory = ["Landing", "Assessment", "NGO_result", "Follow_up"];


 useEffect(() => {
   ReactGA.send({
     hitType: "pageview",
     page: `/${cancerTypes[0]}_${pageviewCategory[page]}`,
   });
 }, [page]);


 return (
   <Grid
     container
     item
     xs={12}
     sx={{
       padding: {
         xs: "0%",
         sm: "5% 5% 5% 5%",
         md: "5% 10% 5% 10%",
       },
       minHeight: "100vh",
       backgroundColor: `${theme.palette.secondary.main}`,
       color: `${theme.palette.primary.main}`,
     }}
   >
     <Grid
       item
       justifyContent="center"
       alignItems="centeer"
       xs={12}
       sx={{
         backgroundColor: "#FFFFFF",
         minHeight: "50vh",
       }}
     >
       <Box sx={{ position: "absolute" }}>
         <Grid sx={{ display: "flex" }}>
           <Link target="_blank" href={`https://www.roche.com.hk/zh.html`}>
             <Grid>
               <img
                 src={rocheaLogo}
                 alt="logo"
                 style={{
                   width: "15vw",
                   minWidth: "70px",
                   maxWidth: "90px",
                   height: "auto",
                   padding: "10px 10px 0 10px",
                 }}
               />
             </Grid>
           </Link>
           <Divider orientation="vertical" variant="middle" flexItem />
           <Grid>
             <img
               src={cancer101Logo}
               alt="logo"
               style={{
                 width: "10vw",
                 minWidth: "40px",
                 maxWidth: "45px",
                 height: "auto",
                 padding: "10px 0 0 10px",
               }}
             />
           </Grid>
         </Grid>
       </Box>


       {/* 如何使用 */}
       {/* {page === 0 ? (
         <Grid container item justifyContent="end">
           <Grid
             sx={{
               position: "absolute",
               display: "flex",
               flexFlow: "column",
               alignItems: "center",
               cursor: "pointer",
               paddingRight: 1,
               paddingTop: 1,
               ":hover": {
                 textDecoration: "underline",
               },
             }}
             onClick={() => {
               window.open("https://www.roche.com.hk/zh.html", "_blank");
             }}
           >
             <InfoIcon />
             <Typography variant="inherit">如何使用</Typography>
           </Grid>
         </Grid>
       ) : (
         ""
       )} */}
       {pages[page]}


       
      
       
     </Grid>

     <Grid
       item
       justifyContent="center"
       alignItems="centeer"
       xs={12}
       sx={{
         
         
         
       }}
       
     >
      <div style={{ margin: "20px 0"}} >
     
   
   
     <Footer />
     </div>

     </Grid>
     

     
     
   </Grid>
 );
};


export default MainPage;




