import React, { useState, useEffect } from "react";
import { Grid, Typography, Select, MenuItem, SelectChangeEvent, Paper } from "@mui/material";
import SelectBaseButton from "../shareComponents/button/SelectBaseButton";
import useButtonCSSHook from "./ButtonCSSHook";
import { OptionSet } from "../../utils/types/services/optionSet";
import { DropdownSet } from "../../utils/types/services/dropdownSet";
import { sanitize } from "dompurify";

interface BiomarkerorGenticProps {
  data: string[];
  updateAnswer4: (data: string[]) => void;
  optionSet: OptionSet[] | undefined;
  dropdownset?: DropdownSet[];
}

const BiomarkerorGenticForm = (props: BiomarkerorGenticProps) => {
  const { updateAnswer4, data, dropdownset } = props;
  const { selectedBtn, defaultBtn } = useButtonCSSHook();
  const [selectedOption1, setSelectedOption1] = useState<string>('');
  const [buttonStyles, setButtonStyles] = useState<Record<string, any>>({});
  const [showAdditionalInputs, setShowAdditionalInputs] = useState(false);


  // Use useEffect to set input1 to props.data[0] when the component mounts
  useEffect(() => {
    console.log("effect");
    if(props.data[0]){
    setSelectedOption1(props.data[props.data.findIndex(str => str.includes("97"))] || ""); // Set input1
    setButtonStyles({ [data[0]]: selectedBtn });
    if(props.data[0]=='1')
      setShowAdditionalInputs(true);
    }
   
   
    
  }, [props.data]); // Run this effect whenever props.data changes


  const questionSet = props.optionSet
    ? props.optionSet.filter((option) => option.formStep === 4)
    : [];

  const handleOptionClick = (value: string) => {
     if (data.includes(value)) {
      updateAnswer4([]);
      setButtonStyles({});
      setShowAdditionalInputs(false);
    } else {
      
      props.data[0]=value;
      setButtonStyles({ [value]: selectedBtn });
      setShowAdditionalInputs(false);
      
      
      if(props.data[0]!='1'){
        if(props.data.length==2){
          props.data.splice(1,1);
          updateAnswer4([props.data[0]]);}
        else{
          
          updateAnswer4([props.data[0]]);
        }
      }
      else{
        if(props.data.length==2){
          props.data.splice(1,1);
          const temp=props.data[0];
          updateAnswer4([]);

          setShowAdditionalInputs(true);
          
          console.log("trigger");
          
          setButtonStyles({ ['1']: selectedBtn });
          //handleOptionClick('1');
        }
        else{
          const temp=props.data[0];
          updateAnswer4([]);
          
          
          //data[0]='1'
          setShowAdditionalInputs(true);
          
          setButtonStyles({ ['1']: selectedBtn });
          //handleOptionClick('1');
        }
          

          //setButtonStyles({ [value]: selectedBtn });
      }
    }
  };

  const handleSelectChange1 = (event: SelectChangeEvent<string>) => {
    setSelectedOption1(event.target.value);
    console.log("yogesh"); 
    if(event.target.value==""){
    
      props.data.splice(1, 1);
     
      
      setSelectedOption1('');
      updateAnswer4([]);
      setButtonStyles({});
      setShowAdditionalInputs(false);

    }

    

    if(!(props.data.some(str => str.includes(getSubstringUpToSpace(event.target.value)))) && (event.target.value.length!==0)){
      props.data[0]='1'
      props.data.push(event.target.value);
      //props.updateAnswer3(props.data);
      console.log(props.data);
      if(props.data.length===2){
        props.updateAnswer4(props.data);
        console.log(props.data);
        //console.log(event.target.value);
      }
    }
    else if((findSubstringIndex(props.data,getSubstringUpToSpace(event.target.value)))>-1 && (event.target.value.length!==0)){
      props.data[0]='1';
      const oldData = [...props.data];
      oldData[findSubstringIndex(props.data,getSubstringUpToSpace(event.target.value))]=event.target.value;
      //props.data=oldData;
      if(props.data.length===2){
        props.updateAnswer4(oldData);
        console.log(props.data);
        //console.log(event.target.value);
      }
     
    }

   

    
  };




  const getSubstringUpToSpace = function(mainString: string) {
    // Find the index of the first space
    const spaceIndex = mainString.indexOf(' ');
    
    // If spaceIndex is -1, it means there's no space in the string
    // In that case, return the whole string
    if (spaceIndex === -1) {
        return mainString;
    }
    
    // Return the substring from index 0 to spaceIndex
    return mainString.substring(0, spaceIndex);
};


const findSubstringIndex = function(array: string[], substring: string) {
  for (let i = 0; i < array.length; i++) {
    if (getSubstringUpToSpace(array[i]).includes(substring)) {
      return i; // Return the index if substring is found
    }
  }
  return -1; // Return -1 if substring is not found in any string
}








  return (
    <Grid sx={{ width: "100%" }}>
      <Grid container item justifyContent="center">
      <Grid container item justifyContent="center">
        <Typography variant="h4" sx={{ color: "black", padding: "0 0px 0 3px 0" }}>
          你有否接受過生物標記/基因測試?
        </Typography>
      </Grid>
      {questionSet.map((items, index) => (
        <Grid container item justifyContent="center" xs={12} key={index} sx={{ p: 1 }}>
          <SelectBaseButton
            onClick={() => handleOptionClick(items.value.toString())}
            sx={buttonStyles[items.value.toString()] || defaultBtn}
          >
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexFlow: "row nowrap",
              }}
              dangerouslySetInnerHTML={{ __html: sanitize(items.description) }}
            />
          </SelectBaseButton>
          </Grid>
        
      ))}
      {showAdditionalInputs && (
         
        <Grid item xs={12} container alignItems="center" padding={'12px'} width={'50px'}>
          
         
            <Typography variant="h6" sx={{ color: 'black', flex: '0 0 auto', paddingRight: '12px', fontSize: '14px' }}>
              測試結果:
            </Typography>
            <Select
              value={selectedOption1}
              onChange={handleSelectChange1}
              displayEmpty
              style={{ flex: "1", borderRadius: '10px' }} // Adjusted width and border radius
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: '300px',
                    borderRadius: '10px',
                  },
                },
              }}
            >
              <MenuItem value="">請選擇</MenuItem>
              {dropdownset && dropdownset.filter(item => item.category_id === 8).map((item, index) => (
                <MenuItem key={index} value={'97'+" "+item.option_name}>{item.option_name}</MenuItem>
              ))}
            </Select>
            
          
        </Grid>
        
        
        
      )}
      </Grid>
    </Grid>
  );
};

export default BiomarkerorGenticForm;
